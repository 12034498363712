import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useInView } from "framer-motion";

import {
  LinkedIn,
  RightArrow,
  Instagram,
  Facebook,
  TwitterIcon
} from "constants/svgLogos";

// import ImgLeft from "assets/images/Footer/footerLeftImg.png";
// import ImgRight from "assets/images/Footer/footerRightImg.png";
// import Mail from "assets/images/Footer/sms.svg";

import "components/Footer/footer.scss";

const index = () => {
  const footer = useRef();
  const footerIsView = useInView(footer, { amount: 0.6, once: true });
  return (
    <footer className="footer" ref={footer}>
      <div className="container">
        <div className="footerContent">
          <div className="footerMain">
            <div
              style={{
                scale: footerIsView ? "1" : "0.5",
                transition: "all 0.6s ease-in-out"
              }}
            >
              <img
                src="https://dj4gk1hnrapgw.cloudfront.net/footer_LeftImg.png"
                alt="image"
              />
            </div>
            <div className="footerHeading">
              <h2>
                Find Your Next Star Hire with <span>Speed & Accuracy</span>
              </h2>
              <p>
                Transform the way you hire and manage your employees with our
                innovative solutions.
              </p>
              <Link
                to="/get-started"
                onClick={() => {
                  window.gtag("event", "footer_get_started", {
                    event_time: new Date()
                  });
                }}
              >
                <button className="commonBtn">
                  Get Started
                  <RightArrow />
                </button>
              </Link>
            </div>
            <div
              style={{
                scale: footerIsView ? "1" : "0.5",
                transition: "all 0.6s ease-in-out"
              }}
            >
              <img
                src="https://dj4gk1hnrapgw.cloudfront.net/footer_RightImg.png"
                alt="image"
              />
            </div>
          </div>
          <span className="footerMail">
            <img
              src="https://dj4gk1hnrapgw.cloudfront.net/footer_sms.svg"
              alt="Mail"
            />
            <a href="mailto: support@quickrecruit.com">
              support@quickrecruit.com
            </a>
          </span>
        </div>
        <div className="footerLinks">
          <div className="socialLinks">
            <Link
              to="https://www.linkedin.com/company/wequickrecruit/"
              target="_blank"
            >
              <LinkedIn />
            </Link>
            <Link
              to="https://www.instagram.com/wequickrecruit/"
              target="_blank"
            >
              <Instagram />
            </Link>
            <Link to="https://www.facebook.com/wequickrecruit" target="_blank">
              <Facebook />
            </Link>
            <Link to="https://twitter.com/wequickrecruit" target="_blank">
              <TwitterIcon />
            </Link>
            {/* <Link to="https://www.instagram.com/" target="_blank">
              <Instagram />
            </Link>
            <Link to="https://www.facebook.com/" target="_blank">
              <Facebook />
            </Link> */}
          </div>
          <div className="footerLinksPage">
            <div>
              <p>
                <Link to="/terms-conditions" className="footerLink">
                  Terms & Conditions
                </Link>
              </p>
            </div>
            <div>
              <p>
                <Link to="/privacy-policy" className="footerLink">
                  Privacy Policy
                </Link>
              </p>
            </div>
            <div>
              <p>Copyright © 2024</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default index;
