import { BrowserRouter, Routes, Route } from "react-router-dom";
import { lazy } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import "styles/global.scss";

const Home = lazy(() => import("containers/Home"));
const ContactUs = lazy(() => import("containers/ContactUs"));
// const BlogListing = lazy(() => import("containers/BlogListing"));
// const Blog = lazy(() => import("containers/Blog"));
const InterviewAsService = lazy(() => import("containers/InterviewAsService"));
const ResumeAsService = lazy(() => import("containers/ResumeAsService"));
const PlatformAsService = lazy(() => import("containers/PlatformAsService"));
const Integration = lazy(() => import("containers/Integration"));
const Pricing = lazy(() => import("containers/Pricing"));
const ToolsHomePage = lazy(() => import("containers/ToolsHomePage/index"));
const AtsResumeCheckerResult = lazy(() =>
  import("components/Result/JobDescription/index")
);
const ResumeJdSyncerResult = lazy(() =>
  import("components/Result/ResumeJdSyncer/index")
);
const ResumeDataScout = lazy(() =>
  import("components/Result/ResumeDataScout/index")
);
const SmmHome = lazy(() => import("containers/SmmHome/index"));
const PrivacyPolicy = lazy(() => import("containers/PrivacyPolicy/index"));
const TermsCondition = lazy(() => import("containers/TermsConditions/index"));
const Product = lazy(() => import("containers/Product/index"));
const Permission = lazy(() => import("containers/GrantPermissions/index"));
const Apply = lazy(() => import("containers/Apply/index"));
const GetStarted = lazy(() => import("containers/GetStarted/index"));
const StartUps = lazy(() => import("containers/StartUps/index"));
const ItService = lazy(() => import("containers/ItService/index"));
const Enterprices = lazy(() => import("containers/Enterprices/index"));

const DefaultLayout = () => {
  return (
    <>
      <ScrollToTop />
      <Header />
      <Suspense
        fallback={
          <div style={{ height: "100vh", width: "100vw" }}>Loading..</div>
        }
      >
        <Outlet />
      </Suspense>
      <Footer />
    </>
  );
};

// const FooterLess = () => {
//   return (
//     <>
//       <ScrollToTop />
//       <Header />
//       <Outlet />
//     </>
//   );
// };

const NoHeaderFooterLayout = () => {
  return (
    <>
      <Suspense
        fallback={
          <div style={{ height: "100vh", width: "100vw" }}>Loading..</div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
};

function App() {
  const path = window.location.pathname;

  if (path !== "/pricing/permission/apply") {
    sessionStorage.removeItem("searchParams");
    sessionStorage.removeItem("agree");
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#09b48b",
              colorPrimaryActive: "#09b48b",
              colorText: "#758b89",
              colorPrimaryTextActive: "#fff",
              colorBgBase: "#f6f8f8",
              fontFamily: "Manrope"
              // borderRadius:0
            }
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route element={<DefaultLayout />}>
                <Route exact path={"/"} element={<Home />} />
                <Route exact path={"/contact-us"} element={<ContactUs />} />
                {/* <Route exact path={"/blogs"} element={<BlogListing />} /> */}
                {/* <Route exact path={"/blogs/:id"} element={<Blog />} /> */}
                <Route
                  exact
                  path={"/products/interview-as-a-service"}
                  element={<InterviewAsService />}
                />
                <Route
                  exact
                  path={"/products/resume-as-a-service"}
                  element={<ResumeAsService />}
                />
                <Route
                  exact
                  path={"/products/platform-as-a-service"}
                  element={<PlatformAsService />}
                />
                <Route exact path={"/integration"} element={<Integration />} />
                <Route exact path={"/pricing"} element={<Pricing />} />
                {/* <Route exact path={"/product"} element={<Suits />} /> */}
                <Route exact path={"/products"} element={<Product />} />
                <Route
                  exact
                  path={"/products/:id"}
                  element={<ToolsHomePage />}
                />
                <Route
                  exact
                  path={"/products/ats-resume-checker/result"}
                  element={<AtsResumeCheckerResult name="ats-resume-checker" />}
                />
                <Route
                  exact
                  path={"/products/linkedin-optimization/result"}
                  element={
                    <AtsResumeCheckerResult name="linkedin-optimization" />
                  }
                />
                <Route
                  exact
                  path={"/products/job-description-scanner/result"}
                  element={
                    <AtsResumeCheckerResult name="job-description-scanner" />
                  }
                />
                <Route
                  exact
                  path={"/products/resume-jd-syncer/result"}
                  element={<ResumeJdSyncerResult />}
                />
                <Route
                  exact
                  path={"/products/resume-data-scout/result"}
                  element={<ResumeDataScout />}
                />
                <Route
                  exact
                  path={"/products/job-posting-management/app"}
                  element={<SmmHome />}
                />
                <Route
                  exact
                  path={"/privacy-policy"}
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path={"/terms-conditions"}
                  element={<TermsCondition />}
                />

                <Route
                  exact
                  path={"/pricing/permission"}
                  element={<Permission />}
                />

                <Route
                  exact
                  path={"/pricing/permission/apply"}
                  element={<Apply />}
                />

                <Route
                  exact
                  path={"/solutions/start-ups"}
                  element={<StartUps />}
                />

                <Route
                  exact
                  path={"/solutions/it-services"}
                  element={<ItService />}
                />

                <Route
                  exact
                  path={"/solutions/enterprise"}
                  element={<Enterprices />}
                />
              </Route>
              <Route element={<NoHeaderFooterLayout />}>
                <Route exact path={"/get-started"} element={<GetStarted />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
